import { Box } from '@stars-ecom/shared-atoms-ui'
import { LoyaltyContent, LoyaltyHeader } from '@stars-ecom/shared-organisms-ui'
import React from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const LoyaltyPage = (props) => {
  const { pageContext } = props
  const { loyaltyPage } = pageContext

  return (
    <Box direction="column" mt={{ base: '20px', md: '0px' }} w="100vw" backgroundColor="#f6f3f1">
      <Helmet
        bodyAttributes={{
          class: 'chakra-ui-light'
        }}>
        <meta charSet="utf-8" />
        <title>Fidélité</title>
        <meta
          name="description"
          content={`Retrouvez en ligne sur ${siteFormatter(
            pageContext?.website?.title
          )}.fr, l'ensemble des produits présentés lors de votre émission de télé achat. Commandez en ligne !`}
        />
        <meta
          name="keywords"
          content="Achat, vente, téléachat, télé shopping, télé shoping, télé schopping, télé chopping, téléachat, télé achat"
        />
      </Helmet>
      <LoyaltyHeader loyaltyPage={loyaltyPage} />
      <LoyaltyContent loyaltyPage={loyaltyPage} />
    </Box>
  )
}

LoyaltyPage.pageType = 'loyalty'

export default LoyaltyPage
